.customTable {
   font-size: 1.5em;
   font-weight: lighter;
}

.customTable th {
   font-weight: normal;
}

.tableInverted {
   color: white
}

.table-striped > tbody > tr:nth-of-type(odd) > * {
   color: white;
}