.logo-color {
   width: 500px;
   max-width: 80%;
   transform: translate(-50%, 0);
   position: relative;
   left: 50%;
   margin: 50px 0;
}

h1.active {
   border-bottom: 1px white solid;
}

.tab-selector {
   cursor: pointer;
   color: white;
}

.tab-selector:hover {
   color: #0176dd;
   border-color: #0176dd;
}


.card-trans {
   background-color: rgba(0,0,0,0.3);
}

.card-trans2 > .list-group-item {
   background-color: transparent;
}

.card-trans2, .card-trans2 > li {
   border-color: grey !important;
}