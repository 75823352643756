.page-wrapper {
	width: 100%;
	min-height: 100%;

	background: rgb(230, 0, 233);
	background: linear-gradient(135deg, rgba(230, 0, 233, 1) 30%, rgba(1, 118, 221, 1) 100%);
   background-attachment: fixed;
   overflow: hidden;
}

.center {
	width: 50%;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	text-align: center;
}

.logo {
	width: 600px;
	max-width: 80%;
}

.logo-center {
   text-align: center;
}

.logo > g,
.logo > path {
	fill: white;
}

.small-divider {
	margin: 30px auto;
	display: block;
	background-color: white;
	width: 60px;
	height: 5px;
}
