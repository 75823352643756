.playerConfirmed > td {
	color: green !important;
}

.playerNotConfirmed > td {
	color: red !important;
}
.card-active {
	-webkit-box-shadow: 0px 0px 11px 3px #479f76;
	box-shadow: 0px 0px 11px 3px #479f76;
}

.card-prepare {
	-webkit-box-shadow: 0px 0px 11px 3px #ffda6a;
	box-shadow: 0px 0px 11px 3px #ffda6a;
}

.gamePageManageBtn {
   margin-left: 10px;
}

.btnDelete:hover {
   color: red;
}

.score-box-0 {
   background-color: rgba(244, 234, 39, .7);
}

.score-box-1 {
   background-color: rgba(79, 232, 247, .7);
}

.score-box-2 {
   background-color: rgba(240, 142, 144, .7);
}

.score-box-3 {
   background-color: rgba(142, 239, 82, .7);
}